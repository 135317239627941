var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import gql from "graphql-tag";
import { mapMutations } from "vuex";
export default Vue.extend({
    apollo: {
        pendingBankTransferPurchase: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query BankTransferPurchaseQuery($id: ID!) {\n          pendingBankTransferPurchase(id: $id) {\n            __typename\n            id\n            proFormaInvoiceFileUrl\n          }\n        }\n      "], ["\n        query BankTransferPurchaseQuery($id: ID!) {\n          pendingBankTransferPurchase(id: $id) {\n            __typename\n            id\n            proFormaInvoiceFileUrl\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    id: this.$route.params.pendingPurchaseId,
                };
            },
        },
    },
    mounted: function () {
        this.clearCart();
    },
    methods: __assign({}, mapMutations(["clearCart"])),
});
var templateObject_1;
